:global(#landing_hero) {
  height: 515px;
  min-height: auto;
  & img {
    object-position: right;
  }
  & header {
    max-width: 1328px;
    justify-content: start;
    & h1,
    & p,
    & a {
      justify-self: left;
      text-align: left;
    }
    & h1 {
      color: #2c2c2c;
      font-family: "Lato";
      font-size: 64px;
      font-style: normal;
      font-weight: 500;
      line-height: 68px; /* 106.25% */
      letter-spacing: 1px;
      max-width: 625px;
      @media (max-width: 767px) {
        font-size: 36px;
        line-height: 46px;
      }
    }
    & p {
      color: #2c2c2c;
      font-family: "Lato";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 160% */
      letter-spacing: 2px;
      text-transform: uppercase;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    & a {
      width: 260px;
      height: 64px;
      border: none;
      background-color: #c8814d;
      border-radius: 4px;
      color: #fff;
      text-align: center;
      font-family: "Lato";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 2px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
      position: relative;
      @media (max-width: 767px) {
        width: 220px;
        height: 44px;
        font-size: 14px;
      }
      &:after{
        position: absolute;
        content: "";
        height: 33px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z'/%3E%3Cpath fill='%23fff' d='M10.5 2a8.5 8.5 0 1 0 5.262 15.176l3.652 3.652a1 1 0 0 0 1.414-1.414l-3.652-3.652A8.5 8.5 0 0 0 10.5 2M4 10.5a6.5 6.5 0 1 1 13 0a6.5 6.5 0 0 1-13 0'/%3E%3C/g%3E%3C/svg%3E");
        width: 33px;
        left: 16px;
        @media (max-width: 768px) {
          height: 22px;
          width: 22px;
          left: 8px;
        }
      }
    }
  }
  & > div {
    width: 100%;
    @media (max-width: 1023px) {
      background: #ffffffd4;
    }
  }
}
:global(#welcome_section) {
  background-color: #fff;
  & > div {
    & > div {
      max-width: 1420px;
      gap: 76px;
      @media (max-width: 1300px) {
        gap: 32px;
      }
    }
  }
  & :global(#welcome_section-block-2) {
    display: flex;
    align-items: center;
    & h3 {
      color: #2c2c2c;
      font-family: "Lato";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px; /* 125% */
      letter-spacing: 1px;
      @media (max-width: 767px) {
        font-size: 32px;
        line-height: 42px;
      }
    }
    & p {
      color: #2c2c2c;
      font-family: "Lato";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 144.444% */
      letter-spacing: 1px;
      max-width: 580px;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
    & a {
      color: #fff;
      text-align: center;
      font-family: "Lato";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: 1px;
      width: 190px;
      height: 48px;
      background-color: #c7814d;
      border-radius: 0;
      @media (max-width: 767px) {
        font-size: 14px;
        width: 174px;
        height: 44px;
      }
    }
  }
}
:global(#browse_job) {
  background-color: #F2F1F0;
  & header {
    & h2 {
      color: #2c2c2c;
      text-align: center;
      font-family: "Lato";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px; /* 125% */
      letter-spacing: 1px;
      margin: 0 0 68px;
      padding: 0;
      @media (max-width: 767px) {
        font-size: 32px;
        line-height: 42px;
      }
    }
  }
  & > div {
    &:nth-child(2) {
      padding: 56px 0 86px;
    }
  }
}
:global(#vetted_professionals) {
  background-color: #F2F1F0;
  & header {
    & h2 {
      color: #2c2c2c;
      text-align: center;
      font-family: "Lato";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px; /* 125% */
      letter-spacing: 1px;
      margin: 0 0 68px;
      padding: 0;
      @media (max-width: 767px) {
        font-size: 28px;
        line-height: 38px;
      }
    }
  }
  & > div {
    &:nth-child(2) {
      padding: 77px 0 66px;
    }
  }
}
:global(#how_work_section),:global(#how_work_section2) {
  background-color: #fff;
  & header {
    & h2 {
      color: var(--Primary, #c8814d);
      text-align: center;
      font-family: "Lato";
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 56px; /* 140% */
      letter-spacing: -1.5px;
      padding: 0;
      @media (max-width: 767px) {
        font-size: 28px;
        line-height: 38px;
      }
    }
    & p {
      color: #4a4a4a;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: -0.348px;
      max-width: 720px !important;
    }
  }
  & > div {
      padding: 62px 0 93px;
      & > div {
        max-width: 1404px;
        row-gap: 48px;
    }
  }
  & :global(#how_work_section-block-1),
  & :global(#how_work_section-block-2),
  & :global(#how_work_section-block-3),
  & :global(#how_work_section2-block-1),
  & :global(#how_work_section2-block-2),
  & :global(#how_work_section2-block-3) {
    & h3 {
      color: #4a4a4a;
      font-family: Inter;
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 152.381% */
      letter-spacing: -0.75px;
      padding: 0;
    }
    & p {
      color: #4a4a4a;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: -0.348px;
      margin: 4px 0 0;
      margin: 0;
    }
  }
}
:global(#you_bet_section) {
  & header {
    & h2 {
      color: var(--Primary, #c8814d);
      text-align: center;
      font-family: "Lato";
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 56px; /* 140% */
      letter-spacing: -1.5px;
      padding: 0;
      @media (max-width: 767px) {
        font-size: 28px;
        line-height: 38px;
        margin: 0 auto;
      }
    }
    & p {
      color: #4a4a4a;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: -0.348px;
      max-width: 720px !important;
    }
  }
  & > div {
    &:nth-child(2) {
      padding: 62px 0 160px;
      & > div {
        max-width: 1404px;
        row-gap: 48px;
      }
    }
  }
  & :global(#you_bet_section-block-1),
  & :global(#you_bet_section-block-2),
  & :global(#you_bet_section-block-3) {
    & h3 {
      color: #4a4a4a;
      font-family: Inter;
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 152.381% */
      letter-spacing: -0.75px;
      padding: 0;
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 28px;
      }
    }
    & p {
      color: #4a4a4a;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: -0.348px;
      margin: 4px 0 0;
      margin: 0;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  & :global(#you_bet_section-block-4) {
    position: absolute;
    bottom: 66px;
    left: 0;
    right: 0;
    text-align: center;
    & a {
      color: #fff;
      text-align: center;
      font-family: "Lato";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 50px; /* 277.778% */
      letter-spacing: 1px;
      width: 421px;
      height: 48px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1023px) {
        width: 220px;
        height: 40px;
        font-size: 14px;
      }
    }
  }
}
